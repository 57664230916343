import { LayoutProps } from "./_model";
export default function Layout(props: LayoutProps) {
  return (
    <div className="flex flex-row w-full h-full bg#p-bg overflow-hidden">
      <div className="flex flex-col w-20vw h-full overflow-hidden">
        {props.sidebar}
      </div>
      <div className="flex flex-col w-full min-h-full bg-[#F1F2F4] items-center justify-center overflow-hidden p-5">
        <div className="flex flex-col w-100% min-h-100% bg-white overflow-hidden p-5">
          {props.children}
        </div>
      </div>
    </div>
  );
}
